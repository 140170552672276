<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        v-if="idempleadoRepresentanteEmpresaContrato"
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <presupuestocli-contrato-add-form
          v-if="pageLoaded"
          :idempresa="presupuestocli.serie_presupuestocli_idempresa"
          :iddelegacion="presupuestocli.iddelegacion"
          :idtactuacion-tsistema="presupuestocli.idtactuacion_tsistema"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import PresupuestocliContratoAddForm from './components/PresupuestocliContratoAddForm'
import Data from './PresupuestocliContratoAddData'
import { PARAMETRO, PRESUPUESTOCLI } from '@/utils/consts'
import { get } from 'vuex-pathify'

export default {
  components: {
    PresupuestocliContratoAddForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      presupuestocli: {},
      idempleadoRepresentanteEmpresaContrato: null,
    }
  },
  computed: {
    usuarioIdcomercial: get('usuario/idcomercial'),
    presupuestoPendiente () {
      return (
        this.presupuestocli &&
        PRESUPUESTOCLI.estadosPendientes.includes(this.presupuestocli.idest_presupuestocli)
      )
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Crear contrato'
      const parametrosNecesarios = [
        PARAMETRO.parametros.IDEMPLEADO_REPRESENTANTE_EMPRESA_CONTRATOS,
      ]
      const resp = await Data.selectLoadPage(this, this.routeParams.idpresupuestocli, parametrosNecesarios)
      this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
      this.idempleadoRepresentanteEmpresaContrato = resp.data.selectParametro.result.dataset[0].valor
      if (!this.idempleadoRepresentanteEmpresaContrato) {
        this.$alert.showSnackbarError(
          'Falta configurar el parámetro "Empleado representante empresa por defecto" (80337)',
          { persistent: true },
        )
      }
      // el form necesita presupuestocli: serie_presupuestocli_idempresa y idtactuacion_tsistema
      this.pageLoaded = true
    },
    changeFormData (formData) {
      this.formData = formData
    },
    formInvalid () {
      this.$alert.showSnackbarError('Alguno de los pasos del asistente contiene datos incorrectos')
    },
    async submitForm () {
      const inserted = await Data.insert(this, this.formData, this.routeParams.idpresupuestocli)
      this.$appRouter.replace({
        name: 'contratos__contrato-view',
        params: {
          idcontrato: inserted.idcontrato,
        },
      })
    },
  },
}
</script>
