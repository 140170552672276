import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idpresupuestocli, idsParametros) {
    const apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    const apiFilterCuotas = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectPresupuestoCuota', method: 'presupuestoCuota.select', params: { filter: apiFilterCuotas } })
    // parámetros
    const apiFilterParametros = new APIFilter()
    apiFilterParametros.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametros } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async insert (Vue, formData, idpresupuestocli) {
    const respContrato = await Vue.$api.call(
      'presupuestocli.crearContrato',
      {
        idpresupuestocli,
        fcontrato: formData.fcontrato,
        idserie_contrato: formData.idserie_contrato,
        dur_fexcepcional: formData.dur_fexcepcional,
        dur_causa: formData.dur_causa,
        dur_entidad_comunicada: formData.dur_entidad_comunicada,
        idtcontrato: formData.tcontrato.idtcontrato,
        objeto: formData.objeto,
      },
    )
    return respContrato.data.result.dataset[0]
  },
}

